/* eslint-disable react/jsx-pascal-case */
import "./App.css";
import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import routes from "./routes";
import Header from "./pages/Header";
import Footer from "./pages/Footer";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

function App() {
  return (
    <>
      <Header />
      <div className="App">
        <header className="App-header">
          <BrowserRouter>
            <Suspense fallback={loading}>
              <Routes>
                {routes.map((route, idx) => {
                  return (
                    route.element && (
                      <Route
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        element={<route.element />}
                      />
                    )
                  );
                })}
              </Routes>
            </Suspense>
          </BrowserRouter>
        </header>
      </div>
      <Footer />
    </>
  );
}

export default App;
