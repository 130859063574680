/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useLayoutEffect, useState } from "react";
import logo from "../assets/img/Perusahaan/giwangkanaka_nobck.png";
import logo2 from "../assets/img/Perusahaan/khmp.jpg";
import axios from "axios";

export default function DenseAppBar() {
  const [setCounter] = useState(0);
  const counterHannoverGiwang = () => {
    axios
      .get("https://reqserver.aqms.giwangkanaka.co.id/api/hannoverGiwang")
      .then((res) => setCounter(res.data.count))
      .catch((err) => console.log(err));
  };
  const stickyHeader = useRef();
  useLayoutEffect(() => {
    const mainHeader = document.getElementById("mainHeader");
    let fixedTop = stickyHeader.current.offsetTop;
    const fixedHeader = () => {
      if (window.pageYOffset > fixedTop) {
        mainHeader.classList.add("fixedTop");
      } else {
        mainHeader.classList.remove("fixedTop");
      }
    };
    window.addEventListener("scroll", fixedHeader);
  }, []);

  return (
    <header className="header bg-white">
      <div className="mainHeader" id="mainHeader" ref={stickyHeader}>
        <a className="footer-brand" href="/" onClick={counterHannoverGiwang}>
          <img src={logo} width={45} height={45} />
          <img src={logo2} width={45} height={45} />
        </a>
        <div className="text-header">
          {/* <b>GIWANG KANAKA Ltd.</b>
          <b>KHMP Ltd.</b> */}
        </div>
      </div>
    </header>
  );
}
