import React from 'react'
//LandingPage
const LandingPage = React.lazy(() => import("./components/LandingPage"));
// Menu Landing Page
const CyberSecurity = React.lazy(() => import("./components/CyberSecurity"));
const SecurityRobots = React.lazy(() => import("./components/SecurityRobots"));
const RenewableEnergy = React.lazy(() => import("./components/RenewableEnergy"));
const Aertrax = React.lazy(() => import("./components/Aertrax"));
const HydrogenTech = React.lazy(() => import("./components/HydrogenTech"));
const MethanolTech = React.lazy(() => import("./components/MethanolTech"));

// Menu Brochure - PDF
const Invisiron_S1000 = React.lazy(() => import("./components/brochure/flip_pdf/Invisiron_S1000"));
const Invisiron_S4000 = React.lazy(() => import("./components/brochure/flip_pdf/Invisiron_S4000"));
const Invisiron_S6000 = React.lazy(() => import("./components/brochure/flip_pdf/Invisiron_S6000"));
const Oneberry_Kenobi = React.lazy(() => import("./components/brochure/flip_pdf/Oneberry_Kenobi"));
const Oneberry_Obiforce = React.lazy(() => import("./components/brochure/flip_pdf/Oneberry_Obiforce"));
const EFOY_Pro900 = React.lazy(() => import("./components/brochure/flip_pdf/EFOY_Pro900"));
const EFOY_Pro1800 = React.lazy(() => import("./components/brochure/flip_pdf/EFOY_Pro1800"));
const EFOY_Pro2800 = React.lazy(() => import("./components/brochure/flip_pdf/EFOY_Pro2800"));
const EFOY_Pro12000 = React.lazy(() => import("./components/brochure/flip_pdf/EFOY_Pro12000"));
const EFOY_Hydrogen = React.lazy(() => import("./components/brochure/flip_pdf/EFOY_Hydrogen"));
const EFOY_FuelCartridge = React.lazy(() => import("./components/brochure/flip_pdf/EFOY_FuelCartridge"));
const Aertrax_Aqms = React.lazy(() => import("./components/brochure/flip_pdf/Aertrax"));

const routes = [
  //Tampilan Awal
  { path: '/', exact: true, name: 'LandingPage' },
  // Landing Page
  { path: '/', name: 'LandingPage', element: LandingPage },
  // Menu Landing Page
  { path: '/cyber-security', name: 'Cyber Security', element: CyberSecurity },
  { path: '/security-robots', name: 'Security Robot', element: SecurityRobots },
  { path: '/renewable-energy', name: 'Renewable Energy', element: RenewableEnergy },
  { path: '/air-quality-monitoring', name: 'Air Quality Monitoring', element: Aertrax },
  { path: '/hydrogen-technology', name: 'Hydrogen Technology', element: HydrogenTech },
  { path: '/methanol-technology', name: 'Methanol Technology', element: MethanolTech },
  // Menu Brochure
  { path: '/invisiron-s1000', name: 'Invisiron S1000 Brochure', element: Invisiron_S1000 },
  { path: '/invisiron-s4000', name: 'Invisiron S4000 Brochure', element: Invisiron_S4000 },
  { path: '/invisiron-s6000', name: 'Invisiron S6000 Brochure', element: Invisiron_S6000 },
  { path: '/oneberry-kenobi', name: 'Oneberry Kenobi', element: Oneberry_Kenobi },
  { path: '/oneberry-obiforce', name: 'Oneberry Obiforce', element: Oneberry_Obiforce },
  { path: '/efoy-pro-900', name: 'EFOY PRO 900 BROCHURE', element: EFOY_Pro900 },
  { path: '/efoy-pro-1800', name: 'EFOY PRO 1800 BROCHURE', element: EFOY_Pro1800 },
  { path: '/efoy-pro-2800', name: 'EFOY PRO 2800 BROCHURE', element: EFOY_Pro2800 },
  { path: '/efoy-pro-12000', name: 'EFOY PRO 12000 BROCHURE', element: EFOY_Pro12000 },
  { path: '/efoy-hydrogen', name: 'EFOY HYDROGEN BROCHURE', element: EFOY_Hydrogen },
  { path: '/efoy-fuel-cartridge', name: 'EFOY FUEL CARTRIDGE BROCHURE', element: EFOY_FuelCartridge },
  { path: '/aertrax-aqms', name: 'EFOY FUEL CARTRIDGE BROCHURE', element: Aertrax_Aqms },
]

export default routes
