/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

export default function DenseAppBar() {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <footer className="footer bg-white">
      <a
        className="footer-brand"
        href="https://indonesiainternetexpo.com/"
        target="_blank"
        rel="noreferrer"
      >
        <div className="text-footer">
          {/* <b>Indonesia Internet Expo & Summit 2023</b> */}
        </div>
      </a>

      <a>
        {" "}
        <b>Indonesia Internet Expo & Summit 2023</b>
      </a>
      <a
        className="footer-brand"
        href="https://www.giwangkanaka.co.id/"
        target="_blank"
        rel="noreferrer"
      >
        <div className="text-footer">
          {" "}
          {/* <b>©{year} MARKETING AND IT</b> */}
        </div>
      </a>
    </footer>
  );
}
